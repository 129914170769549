import { render, staticRenderFns } from "./signature.vue?vue&type=template&id=f8143c54&scoped=true&"
import script from "./signature.vue?vue&type=script&lang=js&"
export * from "./signature.vue?vue&type=script&lang=js&"
import style0 from "./signature.vue?vue&type=style&index=0&id=f8143c54&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8143c54",
  null
  
)

export default component.exports