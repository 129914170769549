<template>
  <section
    class="v-sign"
    :class="{'width_335':width==335}">
    <div
      v-if="showBeforeSign"
      class="before-btn"
      :style="{
        'width': width + 'px',
        'height': height + 'px'
      }">
      <v-button
        type="cobalt"
        iconLeft="icon-Edit"
        @click="showBeforeSign = false">簽署 Signature</v-button>
    </div>
    <div
      class="sign-box"
      :style="{
        'width': width + 'px',
        'height': height + 'px'
      }"
      :class="{
        'sign-border': !showBeforeSign
      }"
    >
      <div class="icon-list">
        <span
          class="icon-box"
          @click="handlerReset">
          <i class="el-icon-close close" />
        </span>
      </div>
      <span class="sign-tips">
        請於此處簽署 Please sign here
      </span>
      <vue-esign
        ref="esign"
        :width="width"
        :height="height"
        :isCrop="isCrop"
        :lineWidth="lineWidth"
        :lineColor="lineColor"
        :bgColor="bgColor"
      />
    </div>
  </section>
</template>
<script>
// import {
//   uploadOrderFile } from '@/api/order'
import {uploadFile, uploadSignature} from '@/api/common'
export default {
  name: 'VSign',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number],
      default: 480, // 350
    },
    height: {
      type: [Number],
      default: 286, // 200
    },
    isCrop: {
      type: Boolean,
      default: false,
    },
    lineWidth: {
      type: [Number],
      default: 2,
    },
    lineColor: {
      type: String,
      default: '#000000',
    },
    bgColor: {
      type: String,
      default: 'rgba(0, 0, 0, 0)',
    },
    uploadData: {
      type: Object,
      default: () => ({})
    },
    signType: {
      default : ''
    },
    companyName: {
      type: [Number,String],
      default : ''
    }
  },
  data() {
    return {
      // https://github.com/JaimeCheng/vue-esign
      file: null,
      imgSrc: '',
      timer: null,
      mouseLock: true,
      lastX: 0,
      lastY: 0,
      diff: 0,
      maxLine: 0,
      totalLine: 0,
      domTarget: null,
      showBeforeSign: true
    }
  },
  watch: {
    companyName: {
      immediate: true,
      deep: true,
      handler(val) {
        if(val && this.signType == 2) {
          this.initCanvasBg()
        }
      }
    },
  },
  mounted() {
    const canvas = this.$refs.esign.$refs.canvas
    canvas.style.borderRadius = '8px'
    // this.timer = setInterval(() => {
    //   this.$refs.esign.generate()
    //     .then(() => {
    //       this.$emit('input', true)
    //     })
    //     .catch(() => {
    //       this.$emit('input', false)
    //     })
    // }, 1000)

    // this.$nextTick(() => {
    //   this.initCanvasBg()
    // })
    this.handlerListener()
  },
  beforeDestroy() {
    // clearInterval(this.timer)
    this.handlerRemoveListener()
  },
  methods: {
    initCanvasBg() {
      // const { identity, isrd_com_nm } = this.accountInfo || {}

      // if(identity != 2) return
      // const {
      //   cust_typ,
      //   isrd_com_nm
      // } = this.detailData

      // if(cust_typ != 2) return
      if(this.signType != 2) return
      this.$nextTick(()=>{
        const canvas = this.$refs.esign.$refs.canvas
        const ctx = canvas.getContext('2d')
        ctx.fillStyle = this.bgColor
        ctx.fillRect(0, 0, canvas.width, canvas.height)

        const color = '#2E3192'
        const font = 'Times'

        ctx.font = `italic bold ${this.width==335?12:16}px ${font}`
        ctx.fillStyle = `${color}`
        // ctx.fillText('For and on behalf of', 10, 56)
        ctx.fillText('For and on behalf of', 10, this.width==335?56:66)

        ctx.font = `italic bold ${this.width==335?12:16}px ${font}`
        ctx.fillStyle = `${color}`
        if(this.width==335)
          ctx.fillText('Authorized Signature(s)', 205, 150)
        else
          ctx.fillText('Authorized Signature(s)', 310, 236)

        // if(cust_typ == 2) {
        ctx.font = `bold ${this.width==335?12:16}px ${font}`
        ctx.fillStyle = `${color}`
        // ctx.fillText(isrd_com_nm, 10, 43)
        // 畫布最多容納 27 個大寫字母的寬度，計算切一下換行
        let companyName = this.companyName
        // let max = 27
        let maxWidth = 460
        if(ctx.measureText(companyName).width <= maxWidth) {
          // ctx.fillText(companyName, 10, 70)
          ctx.fillText(companyName, 10, this.width==335?70:85)
        }
        else {
          let result = []
          while(ctx.measureText(companyName).width > maxWidth) {
            // 計算多少個字符會超出畫布寬度
            let str
            let max = 27
            for(let i = 0; i < companyName.length; i++) {
              str = companyName.substr(0, i+1)
              if(ctx.measureText(str).width > maxWidth) {
                max = i+1
                break
              }
            }

            // 檢查是否存在空格，有空格直接從空格處斷行
            let isEmpty = -1
            let resultStr = ''
            for(let i = max-1; i >= 0; i--) {
              if(companyName[i] == ' ') {
                isEmpty = i
                break
              }
            }
            if(isEmpty == -1) {
              //  沒有空格直接加個連接符
              resultStr = companyName.substr(0, max-1) + '-'
              companyName = companyName.substring(max-1)
            }
            else {
              resultStr = companyName.substr(0, isEmpty)
              companyName = companyName.substring(isEmpty+1)
            }
            result.push(resultStr)
          }
          result.push(companyName)
          for(let i = 0; i < result.length; i++) {
            // 60: 公司名的 y 坐標
            // 12：公司名的字體大小
            // ctx.fillText(result[i], 10, 60+(i*12))
            if(this.width==480)
              ctx.fillText(result[i], 10, 60+(i*16))
            else {
              ctx.fillText(result[i], 10, 60+(i*12))
            }
          }
        }
        // }


        // 虛線
        ctx.beginPath()
        ctx.lineWidth = 1
        ctx.strokeStyle = color
        ctx.setLineDash([2])

        if(this.width==480)
        {
          ctx.moveTo(10, 210)
          ctx.lineTo(470, 210)
        }
        else
        {
          ctx.moveTo(10, 130)
          ctx.lineTo(325, 130)
        }
        ctx.stroke()
        ctx.closePath()
        ctx.setLineDash([])
        ctx.lineWidth = 2
      })

    },
    handlerReset() {
      this.$refs.esign.reset()
      this.initCanvasBg()
      this.mouseLock = true
      this.lastX = 0
      this.lastY = 0
      this.diff = 0
      this.maxLine = 0
      this.totalLine = 0
      this.$emit('reset')
      this.$emit('input', false)
    },
    handlerCheck() {
      this.$refs.esign.generate()
        .then((res) => {
          // console.log(res, res.length)
          if(this.maxLine > 30 && this.totalLine > (this.width-50)) {
            this.$emit('input', true)
          }
          else {
            this.$emit('input', false)
          }
        })
        .catch(() => {
          this.$emit('input', false)
        })
    },
    async handlerGenerate() {
      let result = null
      await this.$refs.esign
        .generate()
        .then(async (res) => {
          this.imgSrc = res
          this.file = this.base64ToImage(res)
          const data = await this.network().uploadOrderFile()
          result = data
        })
        .catch((err) => {
          console.error(err) // 畫布沒有簽名 Not Signned
        })
      return result
    },
    // https://juejin.im/post/6857065289532735502
    base64ToImage(base) {
      const arr = base.split(',')
      const bstr = window.atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while(n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], 'file', {
        type: 'image/png'
      })
    },
    handlerListener() {
      const canvas = this.$refs.esign.$refs.canvas
      canvas.addEventListener('mousedown', this.handler().handlerStart)
      canvas.addEventListener('mousemove', this.handler().handlerMove)
      canvas.addEventListener('mouseup', this.handler().handlerEnd)
      canvas.addEventListener('mouseout', this.handler().handlerOut)
      canvas.addEventListener('touchstart', this.handler().handlerStart)
      canvas.addEventListener('touchmove', this.handler().handlerMove)
      canvas.addEventListener('touchend', this.handler().handlerEnd)
      canvas.addEventListener('pointerdown', this.handler().handlerStart)
      canvas.addEventListener('pointermove', this.handler().handlerMove)
      // canvas.addEventListener('pointerup', this.handler().handlerEnd)
      canvas.addEventListener('pointerout', this.handler().handlerOut)
      document.body.addEventListener('pointerup', this.handler().handlerGlobalEnd)
    },
    handlerRemoveListener() {
      const canvas = this.$refs.esign.$refs.canvas
      canvas.removeEventListener('mousedown', this.handler().handlerStart)
      canvas.removeEventListener('mousemove', this.handler().handlerMove)
      canvas.removeEventListener('mouseup', this.handler().handlerEnd)
      canvas.removeEventListener('mouseout', this.handler().handlerOut)
      canvas.removeEventListener('touchstart', this.handler().handlerStart)
      canvas.removeEventListener('touchmove', this.handler().handlerMove)
      canvas.removeEventListener('touchend', this.handler().handlerEnd)
      canvas.removeEventListener('pointerdown', this.handler().handlerStart)
      canvas.removeEventListener('pointermove', this.handler().handlerMove)
      // canvas.removeEventListener('pointerup', this.handler().handlerEnd)
      canvas.removeEventListener('pointerout', this.handler().handlerOut)
      document.body.addEventListener('pointerup', this.handler().handlerGlobalEnd)
    },
    handler() {
      return {
        handlerStart: (e) => {
          this.diff = 0
          this.lastX = 0
          this.lastY = 0
          this.mouseLock = false


          const canvas = this.$refs.esign.$refs.canvas
          const rect = canvas.getBoundingClientRect()
          // const x = Math.round(window.screenX + rect.left)
          // const y = Math.round(window.screenY + rect.top)
          const x = Math.round(rect.left)
          const y = Math.round(rect.top)
          this.domTarget = {
            x,
            y,
            endX: x + this.width,
            endY: y + this.height
          }
          console.log(this.domTarget)
        },
        handlerMove: (e) => {
          if(!this.mouseLock) {
            const target = e.touches? e.touches[0]: e
            const {
              screenX, screenY, clientX, clientY
            } = target
            const targetX = Math.round(clientX)
            const targetY = Math.round(clientY)

            // 判断是否 out
            if(this.handler().handlerCheckOut(targetX, targetY)) {
              if(this.lastX && this.lastY) {
                // 判斷移動範圍
                const diffX = Math.abs(targetX - this.lastX)
                const diffY = Math.abs(targetY - this.lastY)
                const diff = diffX + diffY
                this.diff += diff
              }
              this.lastX = targetX
              this.lastY = targetY
            }
            else {
              this.handler().handlerOut()
            }
          }
        },
        handlerEnd: (e) => {
          this.mouseLock = true
          if(this.maxLine < this.diff) {
            this.maxLine = this.diff
          }
          this.totalLine += this.diff

          this.handlerCheck()

          console.log(this.diff, this.maxLine, this.totalLine)
        },
        handlerGlobalEnd: (e) => {
          if(!this.mouseLock) {
            console.log('global out')
            this.handler().handlerEnd(e)
          }
        },
        handlerOut: (e) => {
          // 鼠標滑出 canvas
          if(!this.mouseLock) {
            if(this.maxLine < this.diff) {
              this.maxLine = this.diff
            }
            this.totalLine += this.diff
            console.log(this.diff, this.maxLine, this.totalLine, 'out')
            // init
            this.diff = 0
            this.lastX = 0
            this.lastY = 0

            this.handlerCheck()
          }
        },
        handlerCheckOut: (touchX, touchY) => {
          const { x, y, endX, endY } = this.domTarget

          if(touchX < x || touchX > endX) {
            return false
          }
          if(touchY < y || touchY > endY) {
            return false
          }
          return true
        }
      }
    },
    network() {
      return {
        uploadOrderFile: async () => {
          let params = new FormData()
          params.append('file', this.file)
          params.append('type', 'sign_pic_path')
          // params.append('direct', 1)
          Object.keys(this.uploadData).some(key => {
            params.append(key, this.uploadData[key])
          })

          params.append('is_sign', 1)

          let result= await uploadSignature(params)

          const { data } = result

          // const { data } = await uploadFile(params)
          // const { data } = await uploadOrderFile(params)
          return data
        }
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.v-sign {
  position: relative;
  width: 480px;
  height: 286px;
  background: #F8F8FB;
  &.width_335{
    width: 335px;
    height: 200px;
  }
  .before-btn{
    background: #F8F8FB;
  }
  .sign-box {
    position: relative;
    box-sizing: content-box;
    margin: 0 auto;
    // width: 350px;  // 會被 px to rem 插件轉換，直接寫在 style 裏
    // height: 200px;
    // border: 1px solid #e1e1e1;
    border-radius: 8px;
    background: #fff;
    canvas {
      border-radius: 16px!important;
    }
    &.sign-border {
      border: 2px solid #003EE5;
    }
    .sign-tips {
      position: absolute;
      top: 12px;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      color: $primary;
      font-weight: 400;
      font-size: 16px;
      line-height: 175%;
      letter-spacing: 0.02em;
      font-feature-settings: 'pwid' on;
    }
    .icon-list {
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      .icon-box {
        display: flex;
        justify-content: center;
        align-items: center;
        //width: 30px;
        //height: 30px;
        //border-radius: 100%;
        //background: $dark;
        font-size: 22px;
        color: $dark;
        &:nth-child(2) {
          margin-left: 10px;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .before-btn {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    // width: 100%;
    // height: 100%;
    background: #F8F8FB;
    /* Border/Field */
    border: 1px solid #1A1A1C;
    border-radius: 8px;
    .v-button {
      min-width: 150px;
      /deep/ .button-text {
        white-space: nowrap;
      }
    }
  }
}
</style>
